var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lazy-load",
    {
      attrs: {
        item:
          !_vm.loadingQuestion[_vm.questionId] &&
          _vm.question.questionDetails.questionType,
      },
    },
    [
      _vm.question.essay
        ? _c(
            "div",
            [
              false
                ? _c(
                    "div",
                    [
                      _c(
                        "m-button",
                        {
                          attrs: { small: "", outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [_vm._v("See Group")]
                      ),
                      _vm.isOwner
                        ? _c(
                            "m-button",
                            {
                              staticClass: "ml-3",
                              attrs: {
                                color: "red accent-3",
                                small: "",
                                dark: "",
                                outlined: "",
                                depressed: "",
                              },
                              on: { click: _vm.detachQuestion },
                            },
                            [_vm._v(" Detach From Group ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("essay-viewer", {
                attrs: { "essay-content": _vm.question.essay },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("single-question-form", {
        attrs: {
          "course-id": _vm.courseId,
          question: _vm.question,
          "is-valid": _vm.isValid,
          readonly: "",
        },
        on: {
          "update:isValid": function ($event) {
            _vm.isValid = $event
          },
          "update:is-valid": function ($event) {
            _vm.isValid = $event
          },
          cancel: function ($event) {
            return _vm.$emit("cancel")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }