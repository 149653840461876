<template>
  <div :class="smallScreen ? ``: `container`">
    <page-title
      :previous-location="{ name: 'question-list' }"
      :page-title="!$language ? 'Question Details' : 'প্রশ্নের বিবরণ'"
      class="mt-5"
    >
    </page-title>

    <SingleQuestionDetails
      :course-id="courseId"
      :question-id="questionId"
    ></SingleQuestionDetails>
  </div>
</template>

<script>
import SingleQuestionDetails from "#ecf/question-bank/components/SingleQuestion/Details";

export default {
  name: "SingleQuestionDetailsPage",
  components: {
    SingleQuestionDetails
  },
  // components: { QuestionDetails },
  props: {
    questionId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      deleting: false
    };
  },
  inject: ["$courseOwnerId", "$courseId"],
  computed: {
    smallScreen() {
      return this.$vuetify.breakpoint.width < 800;
    },
    courseId() {
      return this.$courseId();
    },
    questionEditRoute() {
      if (!this.questionId) {
        return "";
      }
      return { name: "question-edit", params: { questionId: this.questionId } };
    },
    groupRoute() {
      if (!this.questionId) {
        return "";
      }
      return {
        name: "question-group-show",
        params: { essayId: this.question.essay_id }
      };
    },
    question() {
      const loadedQuestion = this.$store.state.questionBank.questions[
        this.questionId
      ];
      if (loadedQuestion) {
        loadedQuestion.essay = loadedQuestion.essay_id
          ? this.$store.state.questionBank.essays[loadedQuestion.essay_id]
          : undefined;
        return loadedQuestion;
        // return questionRepository.mapToLocal(loadedQuestion);
      }
      return undefined;
    }
  },
  async created() {},
  methods: {
    async deleteQuestion() {
      try {
        this.deleting = true;
        await this.$store.dispatch("questionBank/delete", {
          questionId: this.questionId
        });
        await this.$router.replace({ name: "question-list" });
        return true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.deleting = false;
      }
    },
    async detachQuestion() {
      try {
        this.detaching = true;
        await this.$store.dispatch("questionBank/detach", {
          questionId: this.questionId
        });
        await this.$store.dispatch("questionBank/get", {
          questionId: this.questionId,
          owner_id: this.$courseOwnerId(),
          course_id: this.$courseId()
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.detaching = false;
      }
    }
  }
};
</script>

<style></style>
