<template>
  <lazy-load
    :item="
      !loadingQuestion[questionId] && question.questionDetails.questionType
    "
  >
    <div v-if="question.essay">
      <!--      corresponding feature support present in backend, keeping this hidden as it is not part of the design right now-->
      <div v-if="false">
        <m-button small outlined @click="$router.go(-1)"
        >See Group</m-button
        >
        <m-button
            v-if="isOwner"
            color="red accent-3"
            class="ml-3"
            small
            dark
            outlined
            depressed
            @click="detachQuestion"
        >
          Detach From Group
        </m-button>
      </div>
      <essay-viewer :essay-content="question.essay"></essay-viewer>
    </div>
    <single-question-form
      :course-id="courseId"
      :question="question"
      :is-valid.sync="isValid"
      readonly
      @cancel="$emit('cancel')"
    ></single-question-form>
  </lazy-load>
</template>

<script>
import SingleQuestionForm from "#ecf/question-bank/components/SingleQuestion/Form";
import EssayViewer from "#ecf/question-bank/components/GroupQuestion/EssayViewer";
import { QuestionProvider, QuestionUpdater, EquationFinder } from "#ecf/question-bank/mixins";
import { cloneDeep } from "lodash";

export default {
  name: "SingleQuestionDetails",
  components: { SingleQuestionForm, EssayViewer },
  mixins: [QuestionUpdater, QuestionProvider, EquationFinder],
  props: {
    courseId: {
      type: String,
      required: true
    },
    questionId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      question: {
        questionDetails: {},
        answers: []
      },
      isValid: true
    };
  },
  inject: ["$courseOwnerId", "$courseId"],
  computed: {
    currentOrganization() {
      return this.$store.state.organization.currentOrganization;
    },
    isOwner() {
      return this.$store.state.user.user.access[
        this.currentOrganization.partner_code
      ].includes("owner");
    }
  },
  async created() {
    const question = await this.fetchQuestion(this.questionId);
    question.essay = question.essay_id
      ? this.$store.state.questionBank.essays[question.essay_id]
      : undefined;
    question.questionDetails.text = await this.findEquationsFromQuestionText(question.questionDetails.text, question.question_id);
    this.question = cloneDeep(question);
  },
  methods: {
    async deleteQuestion() {
      try {
        this.deleting = true;
        await this.$store.dispatch("questionBank/delete", {
          questionId: this.questionId
        });
        await this.$router.replace({ name: "question-list" });
        return true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.deleting = false;
      }
    },
    async detachQuestion() {
      try {
        this.detaching = true;
        await this.$store.dispatch("questionBank/detach", {
          questionId: this.questionId
        });
        this.$root.$emit("alert", ["Success", "Detach successfull"]);
        await this.$store.dispatch("questionBank/get", {
          questionId: this.questionId,
          owner_id: this.$courseOwnerId(),
          course_id: this.$courseId()
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.detaching = false;
      }
    },
  }
};
</script>

<style scoped>
.essay-text {
  text-align: justify;
  text-justify: inter-word;
  overflow: auto;
}
</style>
