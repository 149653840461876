var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.smallScreen ? `` : `container` },
    [
      _c("page-title", {
        staticClass: "mt-5",
        attrs: {
          "previous-location": { name: "question-list" },
          "page-title": !_vm.$language ? "Question Details" : "প্রশ্নের বিবরণ",
        },
      }),
      _c("SingleQuestionDetails", {
        attrs: { "course-id": _vm.courseId, "question-id": _vm.questionId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }